import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "@reach/router";

import Header from "./header";

function Layout({ children }) {
  const location = useLocation();
  return (
    <div className="flex flex-col min-h-screen space-y-12 text-lg transition duration-1000 ease-in-out bg-gray-200 dark:bg-gray-800">
      <Header location={location} />

      <main className="flex-1">
        {children}
      </main>

      <footer className="flex flex-col sm:flex-row justify-between w-full max-w-4xl px-3 pb-3 mx-auto text-sm text-center md:text-left md:pb-6 md:px-6 dark:text-gray-200">
        <span className="my-1">
          Built using{' '}
          <a className="font-bold" href="https://www.gatsbyjs.com" rel="noopener noreferrer" target="_blank">Gatsby</a>{' '}
          and{' '}
          <a className="font-bold" href="https://tailwindcss.com" rel="noopener noreferrer" target="_blank">Tailwind CSS</a>.
        </span>
        <span className="my-1">© {new Date().getFullYear() } Rodrigo Passos</span>
      </footer>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

import React from 'react'
import { ThemeToggler } from "gatsby-plugin-dark-mode"

import "../styles/themeToggle.css"

function ThemeToggle() {
  return (
    <ThemeToggler>
     {({ theme, toggleTheme }) => {

      const isDarkMode = theme === "dark"

      if (theme == null) {
        return null
      }

      return (
        <div className="theme-toggle flex justify-between items-center">
           <label className={`w-14 h-8 flex items-center rounded-full p-1 ${ isDarkMode ? "bg-gray-600" : "bg-yellow-300"}`}>
             <input type="checkbox" className="hidden"
               onChange={e => toggleTheme(e.target.checked ? 'dark' : 'light')}
               checked={isDarkMode}
             />
             <div className={`bg-white w-6 h-6 rounded-full shadow-md duration-300 ease-in-out ${isDarkMode ? "transform translate-x-6" : ""}`} />
           </label>
        </div>
       )
     }}
     </ThemeToggler>
  )
}

export default ThemeToggle

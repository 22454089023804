import React from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery, Link } from "gatsby";

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import ThemeToggle from "./themeToggle"

const Header = ({ location }) => {
  const { site, RodrigoHeadshot } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }

      RodrigoHeadshot: file(relativePath: {eq: "rodrigo-dative-square.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            width: 100
            height: 100
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);

  return (
    <header>
      <div className="flex items-center justify-between w-full max-w-4xl px-3 pt-3 mx-auto md:pt-6 md:px-6">
        <Link to="/" className="no-underline">
          <h1 className="h-12 flex items-center dark:text-gray-200">
            {location.pathname !== '/' && (<GatsbyImage
              image={getImage(RodrigoHeadshot)}
              className="h-12 w-12 mr-2 rounded-full"
              imgClassName="rounded-full"
              alt="Rodrigo Passos Headshot"
              width={100}
              height={100}
            />)}
            <span className="text-2xl font-bold tracking-tight">
              {site.siteMetadata.title}
            </span>
          </h1>
        </Link>
        <ThemeToggle />
      </div>
    </header>
  );
}

Header.propTypes = {
  location: PropTypes.any,
};

export default Header;

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { useLocation } from "@reach/router"
import { Helmet } from "react-helmet";
import { Twitter } from "./twitter";

function SEO({
  title = null,
  description = null,
  image = null,
  article = false,
  keywords = null
}) {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query);

  const {
    defaultTitle,
    defaultDescription,
    defaultImage,
    defaultKeywords,
    twitterUsername,
    siteUrl,
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    keywords: keywords || defaultKeywords,
    url: `${siteUrl}${pathname}`,
  }

  return (
    <>
      <Helmet title={seo.title} titleTemplate={`%s | Rodrigo Passos, Fullstack Web Developer out of Cape Cod, MA`}>
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        <meta name="keywords" content={seo.keywords} />

        {seo.url && <meta property="og:url" content={seo.url} />}

        {(article ? true : null) && <meta property="og:type" content="article" />}

        {seo.title && <meta property="og:title" content={seo.title} />}

        {seo.description && (
          <meta property="og:description" content={seo.description} />
        )}

        {seo.image && <meta property="og:image" content={seo.image} />}
      </Helmet>
      <Twitter
        username={twitterUsername}
        title={seo.title}
        description={seo.description}
        image={seo.image}
      />
    </>
  );
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
  keywords: "",
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
  keywords: PropTypes.string,
};

export default SEO;

const query = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        defaultImage: image
        defaultKeywords: keywords
        author
        twitterUsername
        siteUrl
      }
    }
  }
`